import { api } from ":mods";
import { ENDPOINTS } from "../const";

export async function getTeams() {
  return api
    .get(ENDPOINTS.TEAMS_LIST)
    .then(async (res) => {
      const dataList = res.data;
      if (!dataList) return Promise.reject();
      return Promise.resolve(dataList);
    })
    .catch((err) => {});
}
